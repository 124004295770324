$(function($) {
  window.showFormAjaxResponse = (context) => {
    context = $(context || document);

    context.on('ajax:beforeSend', 'form', (event) => {
      $(this).find('.ajax-validation-error').remove();
    });

    context.on('ajax:success', 'form', (event) => {
      xhr = event.detail[event.detail.length - 1];
      if(xhr && xhr.getResponseHeader('Location')) {
        window.location = xhr.getResponseHeader('Location');
      }
    });

    context.on('ajax:error', 'form', (event) => {
      console.log('1212121212');
      // debugger;
      if (event.detail && event.detail[0] && (event.detail[0].errors || event.detail[0].message)) {
        if (event.detail[0].message) {
          Swal.fire(event.detail[0].message);
          return;
        }

        if (event.detail[0].errors) {
          var not_found_errors = []
          $.each(event.detail[0].errors, (k, v) => {
            let field = k // .split('.');
            // let pattern = '.*?' + field.replaceAll( /\]\[/g,'(_attributes)?(_id)?\\]\\[').replaceAll(/\]/g,'\\]') + '(_attributes)?(_id)?\\]?';

            let pattern = '.*?' + field.replaceAll(/\[/g, '(_attributes)?\\]\\[') + '(_id)?.*?'

            console.log(pattern)
            let field_name_regex = new RegExp(pattern)
            // field = field[ field.length - 1];
            let input = []
            $(this).find("[name]").each( (i, el) => {
              if (el.name.match(field_name_regex)) {
                input = $(el);
                return false
              }
            })
            // input = $ "[data-wr-method-name=#{field}]:visible, [data-wr-field-name=#{field}]:visible", e.target

            // TODO:
            //   здесь идет фильтрация дублирующих значений,
            //   разобраться на сервере откуда появляются дубли

            let msg = v.filter((v, i, a) => a.indexOf(v) === i).join(', ');
            if (input.length > 0) {
              // style = ''
              // style = 'style="pointer-events: auto;"' if msg.indexOf('<a') > -1
              input.closest('li').append(error_div = $("<div class='ajax-validation-error inline-errors'>" + msg + "</div>"));
              var margin = error_div.width()/2
              if (input.offset().left < margin) {
                error_div.addClass('left');
              }
              if ($(document).width() - input.offset().left - input.width() < margin) {
                error_div.addClass('right');
              }
            } else {
              not_found_errors.push([field, msg].join(': '));
            }
          })
          if (not_found_errors.length > 0) {
            Swal.fire({title: 'Проверьте введенные данные:', html: '<ul><li>'+not_found_errors.join('</li><li>')+'</li></ul>'});
          }
        } else {
          Swal.fire('Ошибка', 'Проверьте введенные данные и попробуйте повторить');
        }

      } else {
        Swal.fire('Ошибка', 'Проверьте введенные данные и попробуйте повторить');
      }
    });
  }
  showFormAjaxResponse();
})
